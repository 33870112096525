import React from 'react';
import s from './Pagination.scss';
import {ArrowLeft, ArrowRight} from '../../../icons/dist';
import classNames from 'classnames';

export interface PaginationProps {
  prev: PaginationItem;
  next: PaginationItem;
  isRTL: boolean;
}

export interface PaginationItem {
  text: string;
  href?: string;
  onClick?(event): void;
}

export const enum DataHooks {
  Pagination = 'pagination',
  HeightPlaceholder = 'pagination.height-placeholder',
}

/* eslint-disable react/jsx-no-useless-fragment */
export class Pagination extends React.Component<PaginationProps> {
  private renderLink(item: PaginationItem, rel: 'prev' | 'next'): JSX.Element {
    const {href, onClick} = item;
    const {isRTL} = this.props;

    const content = (
      <>
        {rel === 'prev' ? (
          <>
            <ArrowLeft />
            {item.text}
          </>
        ) : (
          <>
            {item.text}
            <ArrowRight />
          </>
        )}
      </>
    );

    return (
      <a rel={rel} href={href} className={classNames(s[rel], {[s.rtl]: isRTL})} onClick={onClick}>
        {content}
      </a>
    );
  }

  public render(): JSX.Element {
    const {prev, next} = this.props;
    const shouldRenderPagination = next.href || prev.href;

    const FullState = () => (
      <>
        {this.renderLink(prev, 'prev')}
        {this.renderLink(next, 'next')}
      </>
    );

    const HeightPlaceholder = () => (
      <div className={s.hidden} data-hook={DataHooks.HeightPlaceholder}>
        <ArrowLeft />
      </div>
    );

    return (
      <div data-hook={DataHooks.Pagination} className={s.pagination}>
        {shouldRenderPagination ? <FullState /> : <HeightPlaceholder />}
      </div>
    );
  }
}
