import React from 'react';
import {Breadcrumbs} from './Breadcrumbs/Breadcrumbs';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {Pagination, PaginationProps} from './Pagination/Pagination';
import cx from 'classnames';
import s from './Navigation.scss';
import {ProductPagePaginationItem} from '../../types/app-types';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ShortBreadcrumbs as BackBreadcrumbs} from './ShortBreadcrumbs/ShortBreadcrumbs';

export interface NavigationProps {
  className?: string;
  type?: NavigationType;
  renderBreadcrumbs: boolean;
  renderPagination: boolean;
}

export const enum NavigationType {
  Short,
  Long,
  PaginationOnly,
}

export const enum NavigationDataHooks {
  ShortBreadcrumbs = 'short-breadcrumbs',
  Root = 'navigation',
}
class NavigationComponent extends React.Component<NavigationProps & ProvidedGlobalProps & IProvidedTranslationProps> {
  public static defaultProps = {
    type: NavigationType.Long,
    renderBreadcrumbs: true,
    renderPagination: true,
  };

  private navigationProps(): PaginationProps {
    /* istanbul ignore next: todo: test */ const {
      t,
      globals: {
        navigate,
        pagination: {prevProduct = {} as ProductPagePaginationItem, nextProduct = {} as ProductPagePaginationItem},
        isRTL,
      },
    } = this.props;

    return {
      isRTL,
      prev: {
        text: t('BREADCRUMBS_PAGINATION_PREVIOUS'),
        href: prevProduct.fullUrl,
        onClick: (e) => {
          e.preventDefault();
          navigate(prevProduct.partialUrl);
        },
      },
      next: {
        text: t('BREADCRUMBS_PAGINATION_NEXT'),
        href: nextProduct.fullUrl,
        onClick: (e) => {
          e.preventDefault();
          navigate(nextProduct.partialUrl);
        },
      },
    };
  }

  private renderBack() {
    const {
      globals: {
        siteUrl,
        pagePath,
        hideNavigationUrls,
        isRTL,
        experiments: {breadcrumbsAfterHydration},
      },
      t,
    } = this.props;

    const previousPage = pagePath[pagePath.length - 2];
    /* istanbul ignore next */ const href = previousPage ? previousPage.url : siteUrl;
    /* istanbul ignore next */ const pageName = previousPage ? previousPage.name : t('BREADCRUMBS_HOME');
    return (
      <BackBreadcrumbs
        pageName={pageName}
        href={href}
        isRTL={isRTL}
        hideNavigationUrls={hideNavigationUrls}
        t={t}
        shouldRenderAfterHydration={breadcrumbsAfterHydration}
      />
    );
  }

  private renderBreadcrumbs() {
    const {
      pagePath,
      navigateToLink,
      hideNavigationUrls,
      experiments: {breadcrumbsAfterHydration},
    } = this.props.globals;
    return (
      <Breadcrumbs
        useHref={!hideNavigationUrls}
        path={pagePath}
        navigateToLink={navigateToLink}
        shouldRenderAfterHydration={breadcrumbsAfterHydration}
      />
    );
  }

  private renderPagination() {
    const {next, prev, isRTL} = this.navigationProps();
    return <Pagination next={next} prev={prev} isRTL={isRTL} />;
  }

  public render(): JSX.Element {
    const {className, type, renderBreadcrumbs, renderPagination} = this.props;

    return (
      <nav data-hook={NavigationDataHooks.Root} className={cx(className, s.navigation)}>
        <div className={s.breadcrumbs}>
          {renderBreadcrumbs && (type === NavigationType.Short ? this.renderBack() : this.renderBreadcrumbs())}
        </div>
        {renderPagination && type === NavigationType.Long && this.renderPagination()}
      </nav>
    );
  }
}

export const Navigation = withGlobalProps(withTranslations('globals.texts')(NavigationComponent));
